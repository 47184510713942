import request from '@/utils/request'

/**
 * 获取轮播图
 * @returns
 */
export async function slideList (param) {
    return request({
        url: '/api/web/slide',
        method: 'get',
        params: param
    });
}