export default {
    data: function () {
        return {
            // 首页轮播图配置
            oneSwiperOption: {
                autoplay: true,//可选选项，自动滑动
                loop: false, // 循环模式选项
                watchOverflow: true,
                // 如果需要分页器
                pagination: {
                    el: '.swiper-pagination',
                    clickable :true,
                    bulletClass : 'pagination-dot',//需设置.my-bullet样式
                    bulletActiveClass: 'pagination-dot-active'
                },
                // 如果需要前进后退按钮
                navigation: {
                    nextEl: '.index-swiper-next',
                    prevEl: '.index-swiper-prev',
                }
            },
            // 学术交流
            fourSwiperOption: {
                effect: 'fade',
                fadeEffect: {
                    crossFade: true,
                }
            },
            // 研究院动态
            twoSwiperOptionLeft: {
                direction: "vertical",
                effect: 'fade',
                autoHeight: true,
                fadeEffect: {
                    crossFade: true,
                },
                allowTouchMove : false,//禁止鼠标模拟
                thumbs: {
                    swiper: null
                },
            },
            twoSwiperOptionRight: {
                direction: "vertical",
                slidesPerView: 4,
                freeMode: true,
                watchSlidesProgress: true,
                allowTouchMove : false,//禁止鼠标模拟
                multipleActiveThumbs : false,//禁止鼠标模拟
                slideToClickedSlide: true,
                normalizeSlideIndex:false,
                on:{
                    init:function(){
                        for (let i =0;i<this.slides.length;i++){
                            let slideEl = this.slides[i];
                            let that = this;
                            slideEl.addEventListener('mouseover', function() {
                               // let a= that.slideTo(i);
                                if (window.parentSlide){
                                    window.parentSlide.slideTo(i);
                                }
                            });

                            slideEl.addEventListener('mouseout', function() {
                                // 鼠标移出时的操作
                            });
                        }

                    }
                }
            },
            // 出版物
            fiveSwiperOption: {
                slidesPerView: 4,
                spaceBetween: 30,
                slidesPerGroup: 4,
                grabCursor: true,
                navigation: {
                    nextEl: '.index-five-next',
                    prevEl: '.index-five-prev',
                },
                breakpoints: {
                    320: {  //当屏幕宽度大于等于320
                        slidesPerView: 1,
                        slidesPerGroup: 1,
                        spaceBetween: 10
                    },
                    768: {  //当屏幕宽度大于等于768
                        slidesPerView: 2,
                        slidesPerGroup: 2,
                        spaceBetween: 20
                    },
                    1280: {  //当屏幕宽度大于等于1280
                        slidesPerView: 4,
                        slidesPerGroup: 4,
                        spaceBetween: 30
                    }
                }
            },
        }
    },
    methods: {
        // 学术交流前一页
        fourSlidePrev() {
            this.$refs.fourSwiper.$swiper.slidePrev();
        },
        // 学术交流后一页
        fourSlideNext() {
            this.$refs.fourSwiper.$swiper.slideNext();
        },
    }
}